import React from "react";
import "./PrivacyPolicyPage.css";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";

const PrivacyPolicyPage = () => {
  return (
    <>
    <Header />
    <div className="privacy-policy-container">
      <h1 className="main-title">Privacy Policy</h1>

      {/* Introduction Section */}
      <section>
        <p>
          Welcome to <strong>FLCS</strong>! Your privacy is important to us, and this Privacy Policy explains how we collect, use, 
          and protect your personal information when you use our website, <a href="https://www.flcs.in/" target="_blank" rel="noopener noreferrer">https://www.flcs.in/</a>.
        </p>
      </section>

      {/* Information We Collect Section */}
      <section>
        <h2 className="section-title">1. Information We Collect</h2>
        <h3>1.1 Personal Information</h3>
        <p>
          Name, email address, phone number, and other contact details you provide when filling out forms or contacting us. 
          Academic and professional details if you submit an inquiry related to study abroad services.
        </p>
        <h3>1.2 Non-Personal Information</h3>
        <p>
          Browser type, device information, IP address, and usage data collected automatically through cookies or similar technologies.
        </p>
      </section>

      {/* How We Use Your Information Section */}
      <section>
        <h2 className="section-title">2. How We Use Your Information</h2>
        <p>We use the information collected for the following purposes:</p>
        <ul>
          <li>To provide study abroad consultation and related services.</li>
          <li>To improve user experience and optimize our website.</li>
          <li>To respond to inquiries and offer personalized assistance.</li>
          <li>To send newsletters, promotional materials, or updates (with your consent).</li>
        </ul>
      </section>

      {/* Sharing Your Information Section */}
      <section>
        <h2 className="section-title">3. Sharing Your Information</h2>
        <p>We do not sell or rent your personal information. However, we may share your information with:</p>
        <ul>
          <li>Third-party service providers who assist us in delivering our services (e.g., payment processors, CRM platforms).</li>
          <li>Legal authorities if required by law to comply with legal obligations or protect our rights.</li>
        </ul>
      </section>

      {/* Cookies Section */}
      <section>
        <h2 className="section-title">4. Cookies and Tracking Technologies</h2>
        <p>
          Our website uses cookies and similar tools to enhance your browsing experience. By using our Website, you consent to 
          the use of cookies. You can manage cookie preferences through your browser settings.
        </p>
      </section>

      {/* Data Security Section */}
      <section>
        <h2 className="section-title">5. Data Security</h2>
        <p>
          We implement robust security measures to protect your personal information against unauthorized access, alteration, or disclosure. 
          However, no online transmission is entirely secure, and we cannot guarantee absolute security.
        </p>
      </section>

      {/* Your Rights Section */}
      <section>
        <h2 className="section-title">6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal data.</li>
          <li>Withdraw consent for data processing.</li>
          <li>Lodge a complaint with a data protection authority.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at <a href="https://mail.google.com/mail/?view=cm&fs=1&to=services@flcs.in" target="_blank">services@flcs.in</a>.
        </p>
      </section>

      {/* Third-Party Links Section */}
      <section>
        <h2 className="section-title">7. Third-Party Links</h2>
        <p>
          Our Website may include links to third-party websites. We are not responsible for the privacy practices of these external sites.
        </p>
      </section>

      {/* Changes to This Policy Section */}
      <section>
        <h2 className="section-title">8. Changes to This Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date.
        </p>
      </section>

      {/* Contact Section */}
      <section>
        <h2 className="section-title">9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us.
        </p>
        <p>Thank you for trusting <strong>FLCS</strong>. We are committed to protecting your privacy while offering exceptional study abroad services.</p>
      </section>
    </div>
    <Footer/>
   </> 
  );
};

export default PrivacyPolicyPage;
