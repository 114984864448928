import config from "../config";
const API_URL = config.api;
// import cors from 'cors';

// app.use(cors());

export const fetchData = async (endpoint) => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return {
      status: "fail",
      message: "Something went wrong",
    };
  }
};

const noBodyRequest = async (endpoint, reqMethod) => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method: reqMethod,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return {
      status: "fail",
      message: "Something went wrong",
    };
  }
};

const bodyRequest = async (endpoint, reqMethod, requestBody) => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method: reqMethod,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return {
      status: "fail",
      message: "Something went wrong",
    };
  }
};
const bodyRequestForm = async (endpoint, reqMethod, body) => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method: reqMethod,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: body,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return {
      status: "fail",
      message: error.message,
    };
  }
};

export const getFileBlobURL = async (endpoint, filename) => {
  try {
    const res = await fetch(`${API_URL}/${endpoint}/${filename}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const contentType = res.headers.get("Content-Type");
    const buffer = await res.arrayBuffer();
    // console.log("Buffer", buffer);
    const blob = new Blob([buffer], { type: contentType });
    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    return null;
  }
};

export const getFileURL = async (endpoint, filename) => {
  try {
    const res = await fetch(`${API_URL}/${endpoint}/${filename}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const data = await res.json();
    if (data.status === "success") {
      return data.link;
    }
    return "";
  } catch (error) {
    return "";
  }
};

// const arrayBufferToBase64 = (buffer) => {
//   let binary = "";
//   const bytes = new Uint8Array(buffer);
//   const len = bytes.byteLength;
//   for (let i = 0; i < len; i++) {
//     binary += String.fromCharCode(bytes[i]);
//   }
//   return window.btoa(binary);
// };

export const apiGet = (endpoint) => {
  return noBodyRequest(endpoint, "GET");
};
export const apiPost = (endpoint, body) => {
  return bodyRequest(endpoint, "POST", body);
};
export const apiPostForm = (endpoint, body) => {
  return bodyRequestForm(endpoint, "POST", body);
};
export const apiPatch = (endpoint, body) => {
  return bodyRequest(endpoint, "PATCH", body);
};
export const apiPatchForm = (endpoint, body) => {
  return bodyRequestForm(endpoint, "PATCH", body);
};
export const apiDelete = (endpoint) => {
  return noBodyRequest(endpoint, "DELETE");
};
