import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import "./NavMenu.css";
import { apiGet } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setLoginPageShow } from "../../../../reducers/loginPageShow";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Icons
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import BusinessIcon from "@mui/icons-material/Business";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";

const NavMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.loginPageShow.user);

  const [openDrawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (anchorEl) {
        setAnchorEl(null);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [anchorEl]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const handleNavigation = (path) => {
    setDrawer(false);
    setTimeout(() => {
      navigate(path);
    }, 100);
  };

  const handleLogin = (isLogin) => {
    setDrawer(false);
    setTimeout(() => {
      dispatch(setLoginPageShow({ isOpen: true, login: isLogin }));
    }, 100);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    apiGet("user/logout");

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="navbar">
        <ul
          className="nav-menu"
          sx={{
            mr: 2,
            display: {
              xs: "none",
              sm: "none",
            },
          }}
        >
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/explore-universities" className="nav-link">
              Universities
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/explore-courses" className="nav-link">
              Courses
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/blogs" className="nav-link">
              Blogs
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/study-abroad" className="nav-link">
              Study&nbsp;Abroad
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/our-services" className="nav-link">
              Our&nbsp;Services
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/about-us" className="nav-link">
              About&nbsp;Us
            </Link>
          </li>

          {user ? (
            <>
              <li className="nav-item">
                <Button
                  className="nav-link"
                  sx={{
                    textTransform: "none",
                    color: "rgb(227,119,18)",
                    fontSize: 15,
                    height: "2rem",
                    paddingLeft: ".8rem",
                    paddingRight: ".8rem",
                    border: "1px solid rgb(227,119,18)",
                    borderRadius: "20px",
                    "&:hover": {
                      borderColor: "rgb(3,163,137)",
                    },
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  startIcon={<PersonIcon />}
                >
                  User
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleNavigation("/dashboard")}>
                    <DashboardIcon sx={{ color: "gray", mr: 2 }} />
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon sx={{ color: "gray", mr: 2 }} />
                    Logout
                  </MenuItem>
                </Menu>
              </li>
            </>
          ) : (
            <li className="nav-itam login-button">
              <Link
                to="#"
                className="nav-link"
                onClick={() =>
                  dispatch(setLoginPageShow({ isOpen: true, login: true }))
                }
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="drawer-bar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{
              p: 2,
              height: 1,
            }}
          >
            <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
            {user ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <ListItemText primary="Welcome!" />
                <ListItemText primary={`${user.firstName}`} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 2,
                }}
              >
                <Button
                  onClick={() => handleLogin(false)}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "rgb(3,163,137)",
                    m: 0.5,
                    width: 0.5,
                    "&:hover": {
                      backgroundColor: "rgb(3,163,137)",
                    },
                  }}
                >
                  Register
                </Button>
                <Button
                  onClick={() => handleLogin(true)}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    m: 0.5,
                    width: 0.5,
                    borderColor: "gray",
                    color: "gray",
                    "&:hover": {
                      borderColor: "gray",
                    },
                  }}
                >
                  Login
                </Button>
              </Box>
            )}
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2, width: 260 }}>
              <ListItemButton
                onClick={() => handleNavigation("/")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <HomeIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Home" />
              </ListItemButton>
              {user ? (
                <ListItemButton
                  onClick={() => handleNavigation("/dashboard")}
                  sx={{ borderBottom: "1px solid lightgray" }}
                >
                  <DashboardIcon sx={{ color: "gray", mr: 2 }} />
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              ) : (
                ""
              )}
              <ListItemButton
                onClick={() => handleNavigation("/explore-universities")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <SchoolIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Universities" />
              </ListItemButton>
              <ListItemButton
                onClick={() => handleNavigation("/explore-courses")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <CardMembershipIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Courses" />
              </ListItemButton>
              <ListItemButton
                onClick={() => handleNavigation("/blogs")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <LocalLibraryIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Blogs" />
              </ListItemButton>
              {/* <ListItemButton
                onClick={() => handleNavigation("/study-abroad")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <ContactMailIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Study Abroad" />
              </ListItemButton> */}
              {/* <ListItemButton
                onClick={() => handleNavigation("/our-services")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <ContactMailIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Our Services" />
              </ListItemButton> */}
              <ListItemButton
                onClick={() => handleNavigation("/about-us")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <BusinessIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="About Us" />
              </ListItemButton>
              {/* <ListItemButton
                onClick={() => handleNavigation("/")}
                sx={{ borderBottom: "1px solid lightgray" }}
              >
                <ContactMailIcon sx={{ color: "gray", mr: 2 }} />
                <ListItemText primary="Contact Us" />
              </ListItemButton> */}
            </Box>
            {user ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "0",
                  width: 200,
                }}
              >
                <ListItemButton
                  onClick={handleLogout}
                  sx={{
                    mb: 2,
                    borderBottom: "1px solid lightgray",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <LogoutIcon sx={{ color: "gray", mr: 2 }} />
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Drawer>
      </div>
    </div>
  );
};

export default NavMenu;
