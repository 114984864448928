import React, { useState } from "react";
import "./Header.css";
import SearchBar from "./SearchBar/SearchBar";
import NavMenu from "./NavMenu/NavMenu";
import { Link } from "react-router-dom";
import logo from "./../../../assets/images/flcs_logo.png";
// import ContactBar from "./ContactBar/ContactBar";
import ContactBar2 from "./ContactBar2/ContactBar";
import LoginDisplay from "../../common/LoginDisplay/LoginDisplay";
import { useSelector } from "react-redux";
// import { setLoginPageShow } from "../../../reducers/loginPageShow";
import Dialog from "@mui/material/Dialog";

const Header = () => {
  const loginPageShow = useSelector((state) => state.loginPageShow.isOpen);
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "25rem",
            padding: "1%",
          },
        }}
        open={loginPageShow}
      >
        <LoginDisplay />
      </Dialog>

      {/* <ContactBar /> */}
      <ContactBar2 />
      <div className="header_container sticky" id="myHeader">
        <div className="header grid-container">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="search_div">
            <SearchBar />
          </div>
          <NavMenu />
        </div>
      </div>
    </>
  );
};

export default Header;
