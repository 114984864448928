import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import "./ContactBar.css";
import { Link } from "react-router-dom";
const ContactBar = () => {
  return (
    <div className="contact_bar">
      <div className="contact_bar_tag">
        <span>Future Leading Consultancy Services (FLCS)</span>
      </div>
      <div className="contact_bar_info">
        <div className="contact-item contact-item-2">
          <CallIcon fontSize="small" />
          <div style={{ paddingLeft: ".5rem", marginBottom: ".2rem" }}>
            +91-9639-036-869
          </div>
        </div>
        <div className="tilted-line"></div>

        <div className="contact-item contact-item-3">
          <EmailIcon fontSize="small" />
          <div
            style={{
              paddingLeft: ".5rem",
              marginBottom: ".2rem",
              wordBreak: "break-all",
            }}
          >
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=services@flcs.in"
              target="_blank"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              services@flcs.in
            </a>
          </div>
        </div>

        <div className="tilted-line"></div>
        <div className="contact-item contact-item-4">
          <div style={{ paddingRight: ".7rem", marginBottom: ".2rem" }}>
            Follow
          </div>
          <div className="contact-social-media">
            {/* <FacebookOutlinedIcon style={{ cursor: "pointer" }} /> */}
            <Link
              to="https://www.instagram.com/kulwant_in_italy"
              target="_blank"
            >
              <InstagramIcon
                fontSize="small"
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              />
            </Link>
            <Link
              to="https://www.youtube.com/@livinginitalykulwant"
              target="_blank"
            >
              <YouTubeIcon
                fontSize="small"
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              />
            </Link>
            <Link
              to="https://www.linkedin.com/in/kulwantsinghrathore/"
              target="_blank"
            >
              <LinkedInIcon
                fontSize="small"
                style={{ color: "white", cursor: "pointer" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBar;
