import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CallIcon from "@mui/icons-material/Call";
import LockIcon from "@mui/icons-material/Lock";
import Person2Icon from "@mui/icons-material/Person2";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CircularProgress from "@mui/material/CircularProgress";
import EmailIcon from "@mui/icons-material/Email";

import { MuiOtpInput } from "mui-one-time-password-input";
import { toast } from "react-toastify";

import "./LoginDisplay.css";
import { apiPost } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { setLoginPageShow, setUser } from "../../../reducers/loginPageShow";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const isLoginPage = useSelector((state) => state.loginPageShow.login);

  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(isLoginPage ? "login" : "signup");

  const [otp, setOtp] = React.useState("");
  const [serverMessage, setServerMessage] = useState("");
  const [serverMessageColor, setServerMessageColor] = useState("black");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const [loginForm, setLoginForm] = useState({
    phone: "",
    email: "",
    userId: "",
    password: "",
  });
  const [signupForm, setSignupForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleSignupSubmit = async () => {
    if (!signupForm.firstName) {
      setServerMessage("Provide First Name!");
      setServerMessageColor("red");
      return;
    }
    if (!signupForm.email) {
      setServerMessage("Provide Email Address!");
      setServerMessageColor("red");
      return;
    }

    setLoading(true);
    const requestBody = {
      firstName: signupForm.firstName,
      lastName: signupForm.lastName,
      email: signupForm.email,
      phone: signupForm.phone,
      password: signupForm.password,
    };

    const res = await apiPost("user/signup/email", requestBody);
    if (res.status === "success") {
      if (res.action === "verifyotp") {
        setAction("otp-signup");
        setServerMessage(res.message);
        setServerMessageColor("green");
        // console.log(res.message);
        toast.info(res.message);
      }
    } else {
      setServerMessageColor("red");
      setServerMessage(res.message);
      // toast.error(res.message);
    }
    setLoading(false);
  };
  const handleOtpSubmit = async () => {
    setLoading(true);
    const requestBody = {
      email: action === "otp-signup" ? signupForm.email : loginForm.email,
      otp,
    };

    const res = await apiPost(
      `user/${
        action === "otp-signup" ? "signup" : "login"
      }/email?action=verify`,
      requestBody
    );
    if (res.status === "success") {
      localStorage.setItem("user", JSON.stringify(res.user));
      localStorage.setItem("token", res.token);

      dispatch(setUser(res.user));
      dispatch(setLoginPageShow(false));
      toast.success(
        `You are logged in as ${res.user.firstName} ${res.user.lastName}`
      );
    } else {
      setServerMessageColor("red");
      setServerMessage(res.message);
      // toast.error(res.message);
    }
    setLoading(false);
  };
  const handleLoginSubmit = async (isOtp) => {
    setLoading(true);
    const requestBody = {
      email: loginForm.email,
      userId: loginForm.email,
      password: loginForm.password,
    };

    const res = isOtp
      ? await apiPost("user/login/email", requestBody)
      : await apiPost("user/login", requestBody);

    if (res.status === "success") {
      if (res.action === "verifyotp") {
        // console.log(res.message);
        setAction("otp-login");
        setServerMessage(res.message);
        setServerMessageColor("green");
        toast.info(res.message);
      } else {
        localStorage.setItem("token", res.token);
        localStorage.setItem("user", JSON.stringify(res.user));
        dispatch(setUser(res.user));
        dispatch(setLoginPageShow(false));
        toast.success(
          `You are logged in as ${res.user.firstName} ${res.user.lastName}`
        );
      }
    } else {
      setServerMessageColor("red");
      setServerMessage(res.message);
      // toast.error(res.message);
    }
    setLoading(false);
    // console.log(data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (action === "signup") {
      setSignupForm({ ...signupForm, [name]: value });
    } else {
      setLoginForm({ ...loginForm, [name]: value });
    }
  };

  return (
    <div className="login-container">
      {!(action === "otp-signup" || action === "otp-login") ? (
        <div className="login-div">
          <div className="login-close-btn">
            <div onClick={() => dispatch(setLoginPageShow(false))}>
              <CloseIcon fontSize="medium" sx={{ color: "gray", m: 1 }} />
            </div>
          </div>
          <h2 className="text-3xl">
            {action === "login" ? "Login" : "Create Your Account"}
          </h2>
          <p
            style={{
              color: serverMessageColor,
              marginBottom: ".5rem",
              minHeight: "1.5rem",
              textAlign: "center",
            }}
          >
            {serverMessage}
          </p>
          <div className="signup-name-div">
            {action === "signup" ? (
              <>
                <div className="login-input signup-input">
                  <input
                    disabled={loading ? true : false}
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={signupForm.firstName}
                    onChange={handleInputChange}
                  />
                  <Person2Icon sx={{ color: "gray" }} fontSize="small" />
                </div>

                <div className="login-input signup-input">
                  <input
                    disabled={loading ? true : false}
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={signupForm.lastName}
                    onChange={handleInputChange}
                  />
                  <PersonOutlineIcon sx={{ color: "gray" }} fontSize="small" />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* {action === "signup" ? ( */}
          <div className="login-input">
            <input
              type="email"
              disabled={loading ? true : false}
              name="email"
              placeholder="Email"
              value={action === "signup" ? signupForm.email : loginForm.email}
              onChange={handleInputChange}
            />
            <EmailIcon sx={{ color: "gray" }} fontSize="small" />
          </div>
          {/* ) : (
            ""
          )} */}
          {action === "signup" ? (
            <>
              <div className="login-input">
                <PhoneInput
                  country={"in"}
                  disabled={loading ? true : false}
                  value={
                    action === "signup" ? signupForm.phone : loginForm.phone
                  }
                  onChange={(value) => {
                    if (action === "signup") {
                      setSignupForm({ ...signupForm, phone: value });
                    } else {
                      setLoginForm({ ...loginForm, phone: value });
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                    // autoFocus: true,
                  }}
                  searchStyle={{
                    height: "30px",
                    padding: "14px",
                    borderRadius: "4px",
                  }}
                  containerStyle={{ width: "88%" }}
                  inputStyle={{
                    width: "90%",
                    height: "2.8rem",
                    borderRadius: "16px",
                    // paddingLeft: "0.6rem",
                    fontSize: "medium",
                    border: "none",
                  }}
                  buttonStyle={{
                    marginLeft: "4px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  dropdownStyle={{
                    "&::WebkitScrollbarThumb": {
                      background: "rgb(3, 163, 137)",
                      borderRadius: "5px",
                    },
                  }}
                  enableSearch={true}
                />
                <CallIcon sx={{ color: "gray" }} fontSize="small" />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="login-input">
            <input
              type="password"
              disabled={loading ? true : false}
              name="password"
              placeholder={
                action === "signup" ? "New Password" : "Enter Password"
              }
              value={
                action === "signup" ? signupForm.password : loginForm.password
              }
              onChange={handleInputChange}
            />
            <LockIcon sx={{ color: "gray" }} fontSize="small" />
          </div>
          <div className="login-button">
            {loading ? (
              <button>
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={28}
                />
              </button>
            ) : action === "signup" ? (
              <button onClick={handleSignupSubmit}>Signup</button>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (loginForm.email === "") {
                      setServerMessage("Please Enter Your Email!");
                      setServerMessageColor("red");
                      return;
                    }
                    if (loginForm.password === "") {
                      setServerMessage("Please Enter Password!");
                      setServerMessageColor("red");
                      return;
                    }
                    handleLoginSubmit();
                  }}
                >
                  Submit
                </button>
              </>
            )}
            {action === "login" ? (
              <div className="login-with-otp">
                <button
                  disabled={loading}
                  onClick={() => {
                    handleLoginSubmit(true);
                  }}
                >
                  Login Using OTP
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="login-change-option">
            {action === "signup" ? (
              <button
                onClick={() => {
                  setServerMessage("");
                  setServerMessageColor("");
                  setAction("login");
                }}
              >
                Already have an account? Login
              </button>
            ) : (
              <button
                onClick={() => {
                  setServerMessage("");
                  setAction("signup");
                  setServerMessageColor("");
                }}
              >
                Don't have an account? Signup
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="login-otp-div">
          <h2 style={{ marginBottom: "1.4rem", color: "RGB(76, 76, 76)" }}>
            Enter OTP
          </h2>
          <MuiOtpInput
            className="login-otp-input"
            autoFocus
            disabled={true}
            value={otp}
            style={{ size: 18 }}
            // validateChar={validateChar}
            TextFieldsProps={{
              type: "number",
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
            }}
            onChange={handleChange}
          />

          <div className="login-otp-text">
            <button
              disabled={loading ? true : false}
              onClick={() => {
                if (action === "otp-signup") handleSignupSubmit();
                if (action === "otp-login") handleLoginSubmit();
              }}
            >
              Resend OTP
            </button>
          </div>
          <div className="login-otp-text-msg">
            <span style={{ color: serverMessageColor, textAlign: "center" }}>
              {serverMessage}
            </span>
          </div>
          <div className="login-otp-button">
            {loading ? (
              <button>
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={28}
                />
              </button>
            ) : (
              <>
                <button onClick={handleOtpSubmit}>Submit</button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginScreen;
